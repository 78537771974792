import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { parseSearchFilters, convertSearchFiltersToQueryObject } from '@/components/SearchFilters/utils'
import { extractTruthyFields, parseLocationFiltersToOverride } from '@/stores/searchFiltersStore/utils'
import { useLocationsStore } from '@/stores/locationsStore'
import { deepCopy, isArray, isObject } from '@/utils/objects'
import { isBoolean, isNumber, isString } from '@/utils/validations/primitives'
import type { SearchFilters } from '@/components/SearchFilters/types'

export const SEARCH_FILTERS_COOKIE_KEY = 'search_filters_v4'
export const defaultFilters: SearchFilters = {
  businessHubs: [],
  clusters: [],
  neighborhoods: [],
  opportunityType: null,
  deliveryTerm: null,
  bedroomsNumber: null,
  price: { min: null, max: null },
  q: null,
  area: { min: null, max: null },
  ids: [],
  parkingLotsNumber: null,
  bathroomsNumber: null,
  location: null
}

export const useSearchFiltersStore = defineStore('searchFilters', () => {
  const { locale } = useI18n()
  const route = useRoute()
  const locationsStore = useLocationsStore()
  const initialFilters = deepCopy(defaultFilters) as SearchFilters

  const generateInitialTouchedFields = (): Record<string, any> => {
    const initialTouchedFields: Record<string, boolean> = {}
    Object.keys(deepCopy(defaultFilters)).forEach((item) => {
      initialTouchedFields[item] = false
    })
    return initialTouchedFields
  }

  const touchedFields = ref<Record<keyof SearchFilters, boolean>>(generateInitialTouchedFields())

  const cacheRefresh = computed(() => route.query.refresh === 'true' || undefined)
  const cachedFilters = useCookie<SearchFilters>(SEARCH_FILTERS_COOKIE_KEY, {
    maxAge: 60 * 60 * 24 * 30,
    default: () => initialFilters
  })

  const locationParams = computed(() => ({
    citySlug: route.params.city as string,
    zoneSlug: route.params.zone as string,
    neighborhoodSlug: route.params.neighborhood as string
  }))

  const isThereAnyLocationParam = computed(() => Object.values(locationParams.value).some(Boolean))

  const currentFilters = ref<SearchFilters>({ ...cachedFilters.value })

  const selectedFilters = ref(deepCopy(currentFilters.value))

  const updateFilters = async (filters: SearchFilters, path?: string) => {
    await setQueryParams(filters, path)
    currentFilters.value = filters
    cachedFilters.value = filters
  }

  const setQueryParams = async (newFilters: SearchFilters, path?: string) => {
    const newQuery: any = {
      bedrooms_number: undefined,
      delivery_term: undefined,
      neighborhoods: undefined,
      parking_lots_number: undefined,
      bathrooms_number: undefined,
      city: undefined,
      zone: undefined,
      neighborhood: undefined,
      ...convertSearchFiltersToQueryObject(newFilters),
      business_hubs: undefined,
      clusters: undefined
    }

    await navigateTo({
      path: path || route.path,
      query: {
        ...route.query,
        ...newQuery
      }
    })
  }

  const filterCount = computed(() => {
    return Object.keys(currentFilters.value).reduce((count: number, key: string) => {
      const value: any = currentFilters.value[key as keyof SearchFilters]

      if (
        isString(value) ||
        isNumber(value) ||
        isBoolean(value) ||
        (isObject(value) && Object.keys(value).some((item: string) => value[item])) ||
        isArray(value)
      ) {
        return count + 1
      }

      return count
    }, 0)
  })

  const initialize = async () => {
    if (!locationsStore.areLocationsLoaded) await locationsStore.initializeLocations()

    const queryFilters = parseSearchFilters({
      clusters: route.query.clusters as (string | null),
      neighborhoods: route.query.neighborhoods as (string | null),
      deliveryTerm: route.query.delivery_term as (string | null),
      bedroomsNumber: route.query.bedrooms_number as (string | null),
      price: route.query.price as (string | null),
      q: route.query.q as (string | null),
      area: route.query.area as (string | null),
      ids: route.query.ids as (string | null),
      parkingLotsNumber: route.query.parking_lots_number as (string | null),
      bathroomsNumber: route.query.bathrooms_number as (string | null),
      city: route.query.city as (string | null),
      zone: route.query.zone as (string | null),
      neighborhood: route.query.neighborhood as (string | null)
    }, locale.value, locationsStore.locationsQueryUtils)

    const truthyQueryFilters = extractTruthyFields(queryFilters)
    const isThereAnyQueryFilter = Object.keys(truthyQueryFilters).length > 0

    if (isThereAnyQueryFilter) {
      cachedFilters.value = {
        ...cachedFilters.value,
        ...truthyQueryFilters
      }
    }

    currentFilters.value = {
      ...cachedFilters.value,
      ...parseLocationFiltersToOverride({ locationParams: locationParams.value, queryFilters, countryCode: locale.value }, locationsStore.locationsQueryUtils)
    }
  }

  return {
    currentFilters,
    selectedFilters,
    locationParams,
    isThereAnyLocationParam,
    cacheRefresh,
    filterCount,
    initialize,
    touchedFields,
    generateInitialTouchedFields,
    updateFilters,
    setQueryParams
  }
})
